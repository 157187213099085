import React from "react"
import Image from "./image"
import { Link } from "gatsby"

const SiteItem = ({ title, url, id, slug, embed }) => {
  const ImageTitle = (
    <>
      <span className="newspapers-grid__item-logo">
        <Image src={id} />
        <span className="newspapers-grid__item-read">Read News</span>
      </span>
      <span>{title}</span>
    </>
  )
  return (
    <div className="newspapers-grid__item">
      {
        embed &&
        <Link to={slug + "view/"} rel="nofollow">
          {ImageTitle}
        </Link>
      }
      {
        ! embed &&
        <a
          href={url}
          rel="nofollow noopener noreferrer"
          target="_blank"
        >{ImageTitle}</a>
      }
      <Link className="see-details" to={slug}>
        See details
      </Link>
      <a
        className="visit-site"
        href={url}
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        Visit site{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-external-link"
        >
          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
          <polyline points="15 3 21 3 21 9"></polyline>
          <line x1="10" y1="14" x2="21" y2="3"></line>
        </svg>
      </a>
    </div>
  )
}

export default SiteItem
